
<template>
    <!-- 商业合作 -->
    <div class="cooperate f-r-s-s">
        <el-row class="cooperate f-r-s-s">
            <el-col class="hidden-md-and-down lg-cooperate f-r-b-c">
                <ul class="f-c-c-c">
                    <li class="title">{{cooperation.title}}</li>
                    <li class="subTitle">{{cooperation.subTitle}}</li>
                    <li class="text">{{cooperation.text}}</li>
                    <!-- <li class="btn f-r-c-c"> 联系我们 </li> -->
                    <li class="btn f-r-c-c">
                        <el-button type="primary" @click="showMore(cooperation)">查看更多</el-button>
                    </li>
                </ul>
                <div class="flex1 cooperate_bg">
                    <img :src="cooperation.bgImg" alt="">
                </div>
            </el-col>
            <el-col class="hidden-lg-and-up md-cooperate f-r-c-c">
                <ul class="f-c-b-c">
                    <li class="title">{{cooperation.title}}</li>
                    <li class="subTitle">{{cooperation.subTitle}}</li>
                    <li class="text">{{cooperation.text}}</li>
                    <li class="btn f-r-c-c">
                        <el-button type="primary" @click="showMore(cooperation)">查看更多</el-button>
                    </li>
                </ul>
            </el-col>
        </el-row>
        
    </div>
</template>
<script>

export default {
    name: "CooperatePage",
    props: ["cooperation"],
    data(){
        return {}
    },
    methods: {
        showMore(item){
            console.log(999)
            let d = {
                isShadow: true,
                shadowIcon: item.icon,
                shadowIconText: item.iconText
            }
            this.$emit("setShadow", d)
        }
    }
}
</script>

<style scoped lang="less">
.cooperate{
    background: rgb(55, 120, 248);
    width: 100%;
    .lg-cooperate{
        width: 100%;
        padding-left: 8%;
        ul{
            cursor: default;
            color: #fff;
            .title{
                font-size: 36px;
                font-weight: 600;
                // line-height: 70px;
            }
            .subTitle{
                padding-top: 15px;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
            }
            .text{
                padding-top: 4px;
                font-size: 12px;
                font-weight: 400;
                line-height: 28px;
            }
            .btn{
                // padding: 6px;
                button{
                    width: 120px;
                }
            }
        }
        .cooperate_bg{
            width: 66%;
            max-width: 66%;
            img{
                width: 100%;
            }
        }
    }
    .md-cooperate{
        width: 100%;
        height: 100%;
        padding: 20px;
        ul{
            cursor: default;
            text-align: center;
            color: #fff;
            .title{
                font-size: 20px;
                font-weight: 600;
                line-height: 48px;
            }
            .subTitle{
                margin-top: 12px;
                font-size: 14px;
                font-weight: 600;
                line-height: 28px;
            }
            .text{
                margin-top: 12px;
                font-size: 12px;
                font-weight: 400;
                line-height: 28px;
            }
            .btn{
                padding: 20px;
                button{
                    width: 120px;
                }
            }
        }
    }
}
</style>