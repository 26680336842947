<template>
	<div class="business-card">
		<el-row class="card" :style="{backgroundImage: `url(${cardImgBg})`}">
			<el-col class="hidden-xs-only pc-text text f-c-c-c">
				<span>{{sub_name}}</span>
				<span>Top Sight Intelligence</span>
				<span>{{idea}}</span>
			</el-col>
			<el-col class="hidden-sm-and-up text">
				<span>{{sub_name}}</span>
				<span>Top Sight Intelligence</span>
				<span>{{idea}}</span>
			</el-col>
			<el-col class="btn f-r-c-e">
				<!-- <router-link  :to="{path: '/contact', query: {sign: technology.sign}}"><el-button>访问官网</el-button></router-link>
				<router-link  :to="{path: '/contact', query: {sign: technology.sign}}"><el-button>联系方式</el-button></router-link> -->
				<!-- <el-button>联系方式</el-button> -->
				<!-- <el-button @click.native="toTop()">访问官网</el-button> -->
				<router-link  :to="{path: '/home'}"><el-button>访问官网</el-button></router-link>
				<router-link  :to="{path: page, query: {sign}}"><el-button>{{class_name}}</el-button></router-link>
			</el-col>
		</el-row>

	</div>
</template>

<script>
	import {mapState} from "vuex"
	export default {
		name: "BusinessCard",
		data() {
			return {
				cardImg: "tel.png",
				class_name: "",
				sign: "",
				page: "",
				idea: "",
				sub_name: ""
			}
		},
		computed: {
			...mapState({footer: 'footer'}),
			cardImgBg: function() {
				let url = this.cardImg;
				return require("@/assets/img/" + url);
			}
		},
		watch: {
			footer: {
				handler(footer) {
					if(footer){
						this.class_name = footer.contact_info.class_name;
						this.page = footer.contact_info.page;
						this.sign = footer.contact_info.sign;
						this.idea = footer.config.idea.value||"";
						this.sub_name = footer.config.sub_name.value||"";
						// this.tel = footer.config.tel.value||"";
						// this.totline = footer.config.totline.value||"";
						// this.email = footer.config.email.value||"";
					}
				},
				deep: true,
				immediate: true,
			}
		},
	}
</script>

<style scoped>
	.business-card{
		background-color: #262626;
	}
	.card {
		margin: 0px auto;
		max-width: 1200px;
		padding: 55px 15px;
		background-position: 15px center;
		background-repeat: no-repeat;
	}

	.text {
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		line-height: 28px;
		color: #fff;
		padding: 0px 0px;
	}

	.pc-text {
		padding: 0px 58px;
	}

	.btn {
		height: 46px;
	}

	.btn button {
		margin-left: 30px;
		margin-right: 30px;
		height: 36px;
		color: #FFF;
		background-color: #df3741;
		border-color: #df3741;
		line-height: 10px;
		border-radius: 2px;
	}

	.btn button:hover {
		background-color: #c4313b !important;
		border-color: #c4313b !important;

	}
</style>
