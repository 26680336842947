import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

import './assets/css/index.css';
Vue.use(ElementUI);
// 全局组件
import TopBg from "./components/topBg.vue";
Vue.component("TopBg", TopBg);
// 全局方法-置顶
Vue.prototype.toTop = ()=>{	
	document.documentElement.scrollTop = 0;
	window.pageYOffset = 0; // 兼容ios
	document.body.scrollTop = 0; // 兼容低版本ie
}
// 全局方法-导航弹起置顶
Vue.prototype.navFixedTop = (box, nav)=>{	
	let navObj = {};
	navObj.position = nav.style.position;
	navObj.zIndex = nav.style.zIndex;
	navObj.top = nav.style.top;
	navObj.width = nav.style.width;
	navObj.maxWidth = nav.style.maxWidth;
	navObj.left = nav.style.left;
	navObj.transform = nav.style.transform;
	navObj.backgroundColor = nav.style.backgroundColor;			
	navObj.boxShadow = nav.style.boxShadow;
	window.onscroll = ()=>{
		let navHeight = nav.clientHeight||0;
		let winTop = document.documentElement.scrollTop;
		if(winTop >= box.offsetTop - navHeight){
			nav.style.position = "fixed";
			nav.style.zIndex = "99";
			nav.style.top = "70px";
			nav.style.width = "100%";
			// nav.style.maxWidth = "1170px";
			nav.style.left = "50%";
			nav.style.transform = "translateX(-50%)";
			nav.style.backgroundColor = "#FFFFFF";
			nav.style.boxShadow = "0 0 10px rgb(0 0 0 / 15%)";
		}
		else{
			nav.style.position = navObj.position;
			nav.style.zIndex = navObj.zIndex;
			nav.style.top = navObj.top;
			nav.style.width = navObj.width;
			// nav.style.maxWidth = navObj.maxWidth;
			nav.style.left = navObj.left;
			nav.style.transform = navObj.transform;
			nav.style.backgroundColor = navObj.backgroundColor;
			nav.style.boxShadow = navObj.boxShadow;
		}
	}
}
// 全局方法-设置关键词，描述
Vue.prototype.setKeywords = (key, des, title)=>{
	// console.log(key, des, title)
	document.title = title;
	document.querySelector("meta[name='keywords']").setAttribute('content', key);
	document.querySelector("meta[name='description']").setAttribute('content', des);
}
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
