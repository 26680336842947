<!-- 浮动功能按钮 -->
<template>
	<el-row class="fixed-btn f-c-c-s">
	
		<el-col class="hidden-xs-only pc-fixed-btn f-c-c-s">
			<el-tooltip  placement="left" effect="light">
				<div slot="content" class="zx fixed_code f-c-c-c">
					<img :src="fixedInfo.zxIcon" alt="">
					<p>{{fixedInfo.zxIconText}}</p>
				</div>
				<!-- 合作咨询 -->
				<div class="tel f-c-c-c">
					<img src="@/assets/img/home/tel_fixed.png" alt="">
					<p>合作咨询</p>
				</div>
			</el-tooltip>
			
			<!-- 微信、抖音、视频 -->
			<div class="ercode f-c-c-c">
				<el-tooltip  placement="left" effect="light">
					<div slot="content" class="fixed_code f-c-c-c">
						<img :src="fixedInfo.wxIcon" alt="">
						<p>{{fixedInfo.wxIconText}}</p>
					</div>
					<div>
						<img src="@/assets/img/home/wx_fixed.png" alt="">
						<p>公众号</p>
					</div>
				</el-tooltip>
				<el-tooltip  placement="left" effect="light">
					<div slot="content" class="fixed_code f-c-c-c">
						<img :src="fixedInfo.dyIcon" alt="">
						<p>{{fixedInfo.dyIconText}}</p>
					</div>
					<div>
						<img src="@/assets/img/home/dy_fixed.png" alt="">
						<p>抖音号</p>
					</div>
				</el-tooltip>
				<el-tooltip  placement="left" effect="light">
					<div slot="content" class="fixed_code f-c-c-c">
						<img :src="fixedInfo.spIcon" alt="">
						<p>{{fixedInfo.spIconText}}</p>
					</div>
					<div>
						<img src="@/assets/img/home/shipin_fixed.png" alt="">
						<p>视频号</p>
					</div>
				</el-tooltip>
			</div>
			<!-- 回到顶部-->
			<div class="top f-c-c-c" @click="toTop()">
				<i class="el-icon-arrow-up"></i>
				<p>返回顶部</p>
			</div>
			
		</el-col>
		<el-col class="hidden-sm-and-up" ></el-col>
	</el-row>
</template>
<script>
	export default {
		name: "FixedBtn",
		props: ["fixedInfo"],
		data() {
			return {}
		},
		computed: {},
		methods: {}
	}
</script>


<style lang="less">
.el-tooltip__popper.is-light{
	border: 0px;
	padding: 0px !important;
	.fixed_code{
		padding: 20px;
		background: #FFF;
		border-radius: 6px;
		box-shadow: 0 0 10px rgb(0 0 0 / 15%);
		transition: all 0.3s;
		img{
			height: 100px;
			background: #FFF;
		}
		p{
			padding-top: 20px;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
		}
	}
	
	.zx{
		background: rgba(231, 246, 255, 1);
	}
}
</style>
<style scoped lang="less">
.fixed-btn{
	position: fixed;
	top: 50%;
	right: 40px;
	z-index: 9998;
	transform: translateY(-50%);
	.pc-fixed-btn{
		// height: 100%;
		width: 52px;
		.tel{
			padding: 0px 8px;
			width: 52px;
			height: 120px;
			border-radius: 30px;
			background: linear-gradient(180deg, #3D78FF 0%, #145DFF 100%);
			box-shadow: 0 0 10px rgb(0 0 0 / 15%);
			img{
				cursor: pointer;
				width: 100%;
			}
			p{
				cursor: pointer;
				text-align: center;
				padding-top: 6px;
				font-size: 14px;
				color: #FFF;
			}
		}
		
		
		.ercode{
			padding: 0px 8px;
			margin-top: 20px;
			width: 52px;
			height: 240px;
			border-radius: 30px;
			background: #FFF;
			box-shadow: 0 0 10px rgb(0 0 0 / 15%);
			.tip{
				height: 100px;
				width: 100px;
			}
			div{
				padding-top: 12px;
				&:nth-of-type(1){
					padding-top: 0px;
				}
				img{
					cursor: pointer;
					width: 100%;
				}
				p{
					cursor: pointer;
					text-align: center;
					padding-top: 4px;
					font-size: 12px;
					color: #8393AC;
				}
			}
		}
		.top{
			cursor: pointer;
			margin-top: 20px;
			width: 56px;
			height: 78px;
			border-radius: 30px;
			font-size: 24px;
			
			font-weight: 600;
			background: #FFF;
			box-shadow: 0 0 10px rgb(0 0 0 / 15%);
			p{
				text-align: center;
				padding-top: 4px;
				font-size: 12px;
				color: #8393AC;

			}
		}
	}
}
</style>


