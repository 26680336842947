<template>
	<div id="app">
		<template v-if="isMap">
			<router-view></router-view>
		</template>
		<template v-else-if="isGzh || isPoster">
			<router-view></router-view>
		</template>
		<template v-else>
			<!-- 头部导航栏 -->
			<Nav :nav="nav.bar" :navTopConfig="nav.config"></Nav>
			<router-view @setShadow="setShadow"></router-view>
			<!-- 名片 -->
			<template v-if="false">
				<BusinessCard></BusinessCard>
			</template>
			<!-- 联系方式 -->
			<template v-if="false">
				<Contact></Contact>
			</template>
			<CooperatePage :cooperation="cooperation" @setShadow="setShadow"></CooperatePage>
			<!-- 底部导航 -->
			<template v-if="true">
				<FooterNav :nav="footerNav" @setShadow="setShadow"></FooterNav>
			</template>
			<!-- 页脚信息 -->
			<Footer :footerInfo="footerInfo"></Footer>
			<!-- 浮窗功能按钮 -->
			<FixedBtn :fixedInfo="fixedInfo"></FixedBtn>
			<div class="shade f-r-c-c" v-if="isShadow" @click="isShadow = false">
				<div class="shade-box f-c-c-c">
					<img :src="shadowIcon" alt="">
					<div v-if="shadowIconText" style="padding-bottom: 10px;">{{shadowIconText}}</div>
					<div class="close"><i class="el-icon-circle-close"></i></div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import Nav from "./components/nav.vue";
	import Footer from "./components/footer.vue";
	import FixedBtn from "./components/fixedBtn.vue";
	import Contact from "./components/contact.vue";
	import BusinessCard from "./components/businessCard.vue";
	import FooterNav from "./components/footerNav.vue";
	import CooperatePage from "./components/cooperate.vue";
	import {
		barService,
		footerService
	} from "@/utils/api/index.js";

	export default {
		name: "App",
		components: {
			Nav,
			Footer,
			FixedBtn,
			Contact,
			BusinessCard,
			FooterNav,
			CooperatePage,
		},
		data() {
			return {
				nav: [],
				cooperation: {		// 商业合作
					title: "",
					subTitle: "",
					icon: "",
					bgImg: "",
					text: "",
					iconText: "",
				},
				footerInfo: {		// 页脚信息
					number: "",
					time: "",
					name: "",
					toUrl: ''
				},
				footerNav: {		// 底部导航
					wxIcon: "",
					dyIcon: "",
					spIcon: "",
					nav: [],
				},
				fixedInfo: {
					wxIcon: "",
					dyIcon: "",
					spIcon: "",
					wxIconText: "",
					dyIconText: "",
					spIconText: "",
					zxIcon: "",
					zxIconText: "",
				},

				isTop: true,		// 是否为顶部
				isMap: false,		// 是否为地图页面

				isShadow: false,	// 弹窗信息		
				shadowIcon: "",		// 弹窗信息		
				shadowIconText: "",	// 弹窗信息		
		
				isGzh: false,		// 公众号
				isPoster: false,	// 公众号海报
			};
		},
		computed: {
			...mapState({
				isGrey: "isGrey",
				nowRouter: "nowRouter"
			}),
		},
		watch: {
			// 辨别当前路由是否为 地图页面 & 辨别是否存在重大事件
			nowRouter: {
				handler(v){
					this.isPoster = v === "/poster";
					this.isGzh = v === "/gzh";
					this.isMap = v === "/map";
					let html = document.getElementsByTagName("html")[0];
					if (this.isGrey == "1" && (v == "home" || v == "/")) {
						html.className = "Mourning";
					} else {
						html.className = "";
					}
				},
				immediate: true

			},
		},
		created() {
			window.addEventListener('scroll', ()=>{
				//获取操作元素最顶端到页面顶端的垂直距离
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				this.isTop = scrollTop <= 360;
			});
		},
		mounted(){
			barService()
			.then(res=>{
				// console.log("导航栏", res)
				if(res.code == 0){
					this.nav = res.data;
				}
				else{
					barService()
					.then(res=>{
						if(res.code == 0){
							this.nav = res.data;
						}
						else{
							this.$message({
								message: res?.mag|| "服务器异常！",
								type: 'warning'
							});
						}
					})
				}
			})
			footerService()
			.then(res=>{
				// console.log("底部导航栏", res)
				if(res.code == 0){
					let d = res.data;
					this.cooperation = {
						title: d.config.bottom_help_title.value,
						subTitle: d.config.bottom_help_sub_title.value,
						text: d.config.bottom_help_desc.value,
						bgImg: d.config.bottom_help_pic.value, 
						icon: d.config.inquire_code.value, 
						iconText: d.config.inquire_code.remark, 
					};
					this.footerInfo = {
						number: d.config.record_number.value,
						time: d.config.record_time.value,
						name: d.config.name.value,
						toUrl: d.config.record_address.value
					}
					let f_nav = [];
					d.about.children.push({
						class_name: "联系我们",
						sign: "about_us",
						page: "/about/index",
						refName: "ab-contact",
					})
					f_nav.push(d.product);
					f_nav.push(d.solution);
					f_nav.push(d.about);
					this.footerNav = {
						email: d.config.email.value,
						wxIcon: d.config.offical_code.value,
						wxIconText: d.config.offical_code.remark, 
						dyIcon: d.config.dy_code.value,
						dyIconText: d.config.dy_code.remark, 
						spIcon: d.config.video_code.value,
						spIconText: d.config.video_code.remark, 
						nav: f_nav
					}

					this.fixedInfo = {
						wxIcon: d.config.offical_code.value, 
						dyIcon: d.config.dy_code.value,
						spIcon: d.config.video_code.value,
						wxIconText: d.config.offical_code.remark, 
						dyIconText: d.config.dy_code.remark, 
						spIconText: d.config.video_code.remark, 
						zxIcon: d.config.inquire_code.value, 
						zxIconText: d.config.inquire_code.remark
					};

				}
			})
		},
		methods: {
			clearShadow(){
				this.isShadow = false;
				this.shadowIcon = "";
				this.shadowIconText = "";
			},
			setShadow(item){
				this.isShadow = item.isShadow;
				this.shadowIcon = item.shadowIcon;
				this.shadowIconText = item.shadowIconText;
			}

		},

	};
</script>

<style>
	/* @font-face {
		font-family: "PingFang SC"; 
		src: url('./assets/css/iconfont/ft/SourceHanSansCN-Normal.otf'),
			url('./assets/css/iconfont/ft/SourceHanSansCN-Light.otf'),
			url('./assets/css/iconfont/ft/SourceHanSansCN-Bold.otf');
		font-weight: normal;
		font-style: normal;
	} */
	body::-webkit-scrollbar {
		display: none;
		color: #353535;
	}
	#app {
		/* font-family:"Hiragino Sans GB", "lvetica Neue",  微软雅黑, Helvetica, Arial, 
			sans-serif; */
			
		/* font-family:"PingFang SC"; */
		/* font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px; */
	}

	.el-carousel__arrow {
		width: 68px !important;
		height: 68px !important;
	}

	.el-carousel__arrow i {
		font-size: 54px !important;
		font-weight: 400;
	}

	/* 新闻详情 */
	#new-detail img {
		max-width: 100% !important;
		height: auto !important;
		max-height: 100% !important;
		object-fit: contain !important;
	}

	#new-detail video {
		max-width: 100% !important;
	}

	.btn button {
		max-width: 116px;
		min-width: 88px;
		margin: 10px;
		line-height: 10px;
		border-radius: 2px;
	}

	/* .el-button:focus,
	.btn button:hover {
		color: #fff !important;
		background-color: #c4313b !important;
		border-color: #c4313b !important;
	} */
	img{
		vertical-align: center;
	}
	
</style>
<style scoped lang="less">
.shade{
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.5);
	.shade-box{
		position: relative;
		background: #FFF;
		padding: 10px;
		border-radius: 10px;
		img{
			width: 50vw;
			max-width: 360px;
			height: 50vw;
			max-height: 360px;
		}
		.close{
			position: absolute;
			right: -36px;
			top: -36px;
			color: #FFFFFFCC;
			height: 30px;
			width: 30px;
			font-size: 30px;
		}
	}

}
</style>
