<template>
	<div class="footer ">
		<div>
			<el-row class="footer">
				<el-col class="hidden-md-and-down lg-footer f-r-a-s">
					<div class="logo"><img src="@/assets/img/logo1.png" alt=""></div>
					<div class="nav">
						<ul class="f-r-a-s">
							<li v-for="p in nav.nav" :key="p.page">
								<p class="title" @click="navTo(p)">{{p.class_name}}</p>
								<p class="text" @click="navTo(pc, p.page)" v-for="(pc, n) in p.children" :key="`pc1_${n}_${pc.page}`">{{ pc.sub_title || pc.title || pc.class_name}}</p>
							</li>
						</ul>
					</div>
					<div class="share">
						<ul class="f-r-b-c">
							<li>
								<el-tooltip placement="bottom" effect="light">
									<div slot="content" class="nav-footer-tip f-c-c-c">
										<img :src="nav.wxIcon" alt="">
										<p>{{nav.wxIconText}}</p>
									</div>
									<img src="@/assets/img/home/weixin_sign.png" alt="">
								</el-tooltip>
							</li>
							<li>
								<el-tooltip placement="bottom" effect="light">
									<div slot="content" class="nav-footer-tip f-c-c-c">
										<img :src="nav.spIcon" alt="">
										<p>{{nav.spIconText}}</p>
									</div>
									<img src="@/assets/img/home/shipin_sign.png" alt="">
								</el-tooltip>
							</li>
							<li>
								<el-tooltip placement="bottom" effect="light">
									<div slot="content" class="nav-footer-tip f-c-c-c">
										<img :src="nav.dyIcon" alt="">
										<p>{{nav.dyIconText}}</p>
									</div>
									<img src="@/assets/img/home/douyin_sign.png" alt="">
								</el-tooltip>
							</li>
						</ul>
						<div class="email">联系邮箱：{{nav.email}}</div>
					</div>
				</el-col>
				<el-col class="hidden-xs-only hidden-lg-and-up md-footer f-r-a-s">
					<div class="f-c-s-s box">
						<div class="share">
							<ul class="f-r-b-c">
								<li>
									<el-tooltip placement="bottom" effect="light">
										<div slot="content" class="nav-footer-tip f-c-c-c">
											<img :src="nav.wxIcon" alt="">
											<p>{{nav.wxIconText}}</p>
										</div>
										<img src="@/assets/img/home/weixin_sign.png" alt="">
									</el-tooltip>
								</li>
								<li>
									<el-tooltip placement="bottom" effect="light">
										<div slot="content" class="nav-footer-tip f-c-c-c">
											<img :src="nav.spIcon" alt="">
											<p>{{nav.spIconText}}</p>
										</div>
										<img src="@/assets/img/home/shipin_sign.png" alt="">
									</el-tooltip>
								</li>
								<li>
									<el-tooltip placement="bottom" effect="light">
										<div slot="content" class="nav-footer-tip f-c-c-c">
											<img :src="nav.dyIcon" alt="">
											<p>{{nav.dyIconText}}</p>
										</div>
										<img src="@/assets/img/home/douyin_sign.png" alt="">
									</el-tooltip>
								</li>
							</ul>
							<div class="email">联系邮箱：{{nav.email}}</div>
						</div>
						<div class="logo"><img src="@/assets/img/logo1.png" alt=""></div>
					</div>
					<div class="nav">
						<ul class="f-r-a-s">
							<li v-for="p in nav.nav" :key="p.page">
								<p class="title" @click="navTo(p)">{{p.class_name}}</p>
								<p class="text" @click="navTo(pc, p.page)" v-for="(pc, n) in p.children" :key="`pc_${n}_${pc.page}`">{{ pc.sub_title || pc.title || pc.class_name}}</p>
							</li>
						</ul>
					</div>
				</el-col>
				<el-col class="hidden-sm-and-up xs-footer">
					
					<div class="share">
						<ul class="f-r-b-c">
							<li @click="seticon('wx')">
								<img src="@/assets/img/home/weixin_sign.png" alt="">
							</li>
							<li @click="seticon('sp')">
								<img src="@/assets/img/home/shipin_sign.png" alt="">
							</li>
							<li @click="seticon('dy')">
								<img src="@/assets/img/home/douyin_sign.png" alt="">
							</li>
						</ul>
						<div class="email">联系邮箱：{{nav.email}}</div>
					</div>
					<el-collapse class="nav" accordion>
						<el-collapse-item v-for="p in nav.nav"  :key="p.page" :title="p.class_name" :name="p.class_name">
							<p class="text" @click.stop="navTo(pc, p.page)" v-for="(pc, n) in p.children" :key="`pc2_${n}_${pc.page}`">{{ pc.sub_title || pc.title || pc.class_name}}</p>
						</el-collapse-item>
					</el-collapse>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script >
	import {mapState} from "vuex"
	export default {
		name: "FooterNav",
		props:["nav"],
		data() {
			return {
				bar: [],
				detailName: "",
				detail: "",
			}
		},
		computed: {
			...mapState({footer: 'footer'})
		},
		watch: {
			footer: {
				handler(footer) {
					if(footer){
						this.bar = footer.bar;
					}
				},
				deep: true,
				immediate: true,
			}
		},
		methods: {
			navTo(item, name=""){
				// console.log(item);
				if(!name || name == "/product"){
					let query = item.sign ? {sign: item.sign} : {};
					this.$router.push({path: item.page, query})
				}
				if(name == "/project"){
					let query = item.id ? {id: item.id} : {};
					this.$router.push({path: "/project/detail", query})
				}
				if(name == "/about/index"){
					let query = item.sign ? {sign: item.sign} : {};
					if(!item.refName){
						query.refName = "ab-about";
					}
					else {
						query.refName = item.refName;
					}
					this.$router.push({path: item.page, query})
				}
			},
			seticon(name){
				if(name == 'wx'){
					this.$emit("setShadow", {
						isShadow: true,
						shadowIcon: this.nav.wxIcon,
						shadowIconText: this.nav.wxIconText,
					})
				}
				if(name == 'sp'){
					this.$emit("setShadow", {
						isShadow: true,
						shadowIcon: this.nav.spIcon,
						shadowIconText: this.nav.spIconText,
					})
				}
				if(name == 'dy'){
					this.$emit("setShadow", {
						isShadow: true,
						shadowIcon: this.nav.dyIcon,
						shadowIconText: this.nav.dyIconText,
					})
				}
			}
		}
	}
</script>

<style lang="less">
	.el-tooltip__popper.is-light{
		border: 0px;
		padding: 0px !important;
		.nav-footer-tip{
			padding: 20px;
			background: #FFF;
			// border: 6px;
			box-shadow: 0 0 10px rgb(0 0 0 / 15%);
			transition: all 0.3s;
			border-radius: 6px;
			img{
				height: 100px;
				background: #FFF;
			}
			p{
				padding-top: 20px;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
			}
		}
	}

	.nav{
		&.el-collapse{
			border: 0px solid #EBEEF5;
		}
		.el-collapse-item__header{
			height: 36px;
			color: #FFF;
			border-bottom: 0px solid #EBEEF5;
			background: transparent;
		}
		.el-collapse-item__content {
			cursor: pointer;
			padding-left: 10px;
			color: #FFFFFF99;
			padding-bottom: 0px;
			
			line-height: 28px;
			background: transparent;
		}
		.el-collapse-item__wrap{
			border-bottom: 0px solid #EBEEF5;
			background: transparent;
		}
	}
</style>
<style scoped lang="less">
.footer{
	width: 100%;
	background: #061127;
	border: 1px solid #3778F84D;
	.lg-footer{
		width: 100%;
		padding: 40px 40px;
		.logo{
			padding-top: 66px;
			width: 280px;
			max-width: 280px;
			img{
				width: 100%;
			}
		}
		.nav{
			width: 600px;
			ul{
				padding: 0px 20px;
				width: 100%;
				li{
					padding-top: 12px;
					.title{
						cursor: pointer;
						margin-bottom: 30px;
						color: #FFFFFF;
						font-size: 20px;
						font-weight: 400;
						line-height: 28px;
					}
					.text{
						cursor: pointer;
						margin-bottom: 14px;
						font-size: 12px;
						font-weight: 400;
						line-height: 28px;
						color: #FFFFFF99;
					}
				}
			}
		}
		.share{
			width: 316px;
			ul{
				width: 100%;
				li{
					width: 68px;
					height: 68px;
					img{
						width: 100%;
					}
				}
			}
			.email{
				color: #FFFFFF;
				padding-top: 70px;
			}
		}	
	}
	.md-footer{
		flex-wrap: wrap;
		padding: 24px;
		
		.box{
			padding-top: 20px;
		}
		.share{
			width: 218px;
			ul{
				width: 100%;
				li{
					width: 42px;
					height: 42px;
					img{
						width: 100%;
					}
				}
			}
			.email{
				font-size: 14px;
				color: #FFFFFF;
				padding-top: 20px;
			}
		}
		.logo{
			padding-top: 42px;
			width: 180px;
			max-width: 180px;
			img{
				width: 100%;
			}
		}
		.nav{
			width: 500px;
			ul{
				padding: 0px 20px;
				width: 100%;
				li{
					padding-top: 12px;
					.title{
						cursor: pointer;
						margin-bottom: 16px;
						color: #FFFFFF;
						font-size: 16px;
						font-weight: 600;
						line-height: 28px;
					}
					.text{
						cursor: pointer;
						margin-bottom: 14px;
						font-size: 12px;
						font-weight: 400;
						line-height: 20px;
						color: #FFFFFF99;
					}
				}
			}
		}
	}
	.xs-footer{
		width: 100%;
		padding: 40px 30px;
		.share{
			width: 218px;
			ul{
				width: 100%;
				li{
					width: 42px;
					height: 42px;
					img{
						width: 100%;
					}
				}
			}
			.email{
				font-size: 14px;
				color: #FFFFFF;
				padding-top: 20px;
			}
		}
		.nav{
			padding-top: 20px;
			width: 100%;
		}
	}
}


</style>



