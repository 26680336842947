<!-- 联系方式 -->
<template>
	<el-row class="contact">
		<el-col class="contact-item f-c-c-c" :xs="24" :sm="8">
			<div class="contact-icon hidden">
				<div class="contact-icon-box">
					<div class="contact-icon-item iconfont icon-tell"></div>
					<div class="contact-icon-item iconfont icon-tell"></div>
				</div>
			</div>
			<div class="contact-title">24小时服务热线</div>
			<div class="contact-text f-c-b-c">
				<div>热线：{{totline}}</div>
				<div>电话：{{tel}}</div>
			</div>
		</el-col>
		<el-col class="contact-item f-c-c-c" :xs="24" :sm="8">
			<div class="contact-icon hidden">
				<div class="contact-icon-box">
					<div class="contact-icon-item iconfont icon-address"></div>
					<div class="contact-icon-item iconfont icon-address"></div>
				</div>
			</div>
			<div class="contact-title">联系地址</div>
			<div class="contact-text f-c-b-c">
				<div>地址：{{address}}</div>
				<div></div>
				<!-- <div>{{welcome_word}}</div> -->
			</div>
		</el-col>
		<el-col class="contact-item f-c-c-c" :xs="24" :sm="8">
			<div class="contact-icon hidden">
				<div class="contact-icon-box">
					<div class="contact-icon-item iconfont icon-email"></div>
					<div class="contact-icon-item iconfont icon-email"></div>
				</div>
			</div>
			<div class="contact-title">联系邮箱</div>
			<div class="contact-text f-c-b-c">
				<div>E-mail：{{email}}</div>
				<div></div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	import {mapState} from "vuex"
	
	export default {
		name: "ContactComponent",
		data(){
			return {
				address: "",
				welcome_word: "",
				tel: "",
				totline: "",
				email: "",
			}
		},
		computed: {
			...mapState({footer: 'footer'})
		},
		watch: {
			footer: {
				handler(footer) {
					if(footer){
						this.address = footer.config.address.value||"";
						this.welcome_word = footer.config.welcome_word.value||"";
						this.tel = footer.config.tel.value||"";
						this.totline = footer.config.totline.value||"";
						this.email = footer.config.email.value||"";
					}
				},
				deep: true,
				immediate: true,
			}
		},
	}
</script>

<style scoped>
	.contact{
		padding: 0px 15px;
		margin: 0px auto;
		max-width: 1200px;
	}
	.contact-item{
		padding: 40px 20px 15px;
		border-bottom: 1px solid #ddd;
	}
	.contact-icon{
		height: 42px;
		width: 42px;
	}
	.contact-item:hover .contact-icon-box{
		margin-top: -100%;
	}
	.contact-item:active .contact-icon-box,
	.contact-icon-box{
		color: #ccc;
		font-size: 42px;
		line-height: 42px;
		text-align: center;
		height: 200%;
		width: 100%;
		transition: all 0.2s linear; 
	}
	.contact-icon-item{
		font-size: 32px;
		height: 50%;
		width: 100%;
	}
	.contact-icon-item:nth-last-of-type(2){
		color: #df3741;
	}
	.contact-title{
		height: 24px;
		margin: 15px 0px 10px;
	}
	.contact-text{
		height: 56px;
		font-size: 14px;
		text-align: center;
		color: rgb(102, 102, 102);
		margin-bottom: 20px;
	}
</style>