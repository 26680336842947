
<!-- 头部背景(从 关于我们 页面提取的公共代码组件，故css名称未变) -->
<template>
	<el-row class="about-us f-c-c-c">
		<!-- pc端 -->
		<el-col class="hidden-xs-only" :sm="24">
			<img :src="aboutUsBg" alt="背景图片" />
			<div class='about-us-pc absolute f-c-c-c'>
				<p v-if="false" class="title">{{title}}</p>
				<p v-if="false" class="text">{{text}}</p>
			</div>
		</el-col>
		<!-- 手机端 -->
		<el-col class="about-us-phone hidden-sm-and-up f-c-c-c" :xs="24"
			:style="{ backgroundImage: `url(${aboutUsBg})`}">
			<p v-if="false" class="title">{{title}}</p>
			<p v-if="false" class="text">{{text}}</p>
		</el-col>
	</el-row>
</template>

<script>
	export default {
		name: "TopBg",
		props: [
			"url",				//  图片名称
			"title",			//  标题
			"text",				// 文本			
		],
		data() {
			return {
				aboutUsUrl: "",
				// title: "关于我们",
				// text: "我们已经上路，我们将为此不懈努力，期待得到您的支持"
			}
		},
		computed: {
			// 关于我们-背景图片
			aboutUsBg: function() {
				// let url = require("@/assets/img/" + this.aboutUsUrl);
				return  this.url ;
			},
		},
		
	}
</script>

<style scoped>
	/* 关于我们 */
	.about-us {
		position: relative;
		width: 100%;
	}
	.about-us-phone {
		width: 100%;
		min-height: 180px;
		text-align: center;
		background-color: #666;
		background-position: center center;
		background-repeat: no-repeat;
		color: #ffffff;
		background-size: auto 100%;
	}
	.about-us-phone .title {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		margin-bottom: 10px;
	}
	.about-us-phone .text {
		height: 28px;
		font-size: 12px;
		font-weight: 400;
		line-height: 28px;
	}
	.about-us-pc {
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
		color: #ffffff;
	}
	.about-us img {
		width: 100%;
		color: #ffffff;
		min-height: 180px;
	}
	.about-us-pc .title {
		font-size: 30px;
		font-weight: 500;
		line-height: 45px;
		margin-bottom: 10px;
	}
	.about-us-pc .text {
		height: 28px;
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;
	}
	
</style>