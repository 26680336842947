<!-- 页脚 -->
<template>
	<div class="footer f-r-c-c">
		<div class="footer-box">
			<a :href="footerInfo.toUrl" target="_blank">{{footerInfo.number}}</a>
			<div class="f-rw-c-c">
				<p>Copyright © {{footerInfo.time}} {{footerInfo.name}} 版权所有</p>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "FooterComponent",
		props: ["footerInfo"],
		data() {
			return {}
		},
		methods: {
			toClick() {
				window.location.href = this.footerInfo.toUrl
			}
		}
	}
</script>
<style scoped>
	.footer {
		position: relative;
		/* position: fixed;
		bottom: 0px; */
		/* margin-top: 20px; */
		width: 100%;
		color: #FFF;
		background-color: #061127;
;
	}
	.footer-box{
		cursor: default;
		font-size: 14px;
		font-weight: 400;
		line-height: 28px;
		/* width: 1180px; */
		padding: 30px 15px 40px;
		text-align: center;
		box-sizing: border-box;
		/* white-space: pre-wrap */
	}
	.footer-map{
		position: absolute;
		bottom: 20px;
		left: 0px;
		width: 100%;
		text-align: center;
	}
</style>
