import { get, post, put, del } from './request.js'
// import request from './request.js'
/** 首页接口 */
export const homeService = (params) => get('/tszn/index', params);
/** 导航栏 */
export const barService = (params) => get('/tszn/bar', params);
/** 详情 */
export const detailService = (params) => get('/tszn/detail', params);
/** 页面接口 */
export const contentService = (params) => get('/tszn/content', params);
/** 页面接口1 */
export const signService = (params) => get('/tszn/sign', params);
/** 页脚接口 */
export const footerService = (params) => get('/tszn/footer', params);
/** 地图接口 */
export const mapService = (params) => get('/tszn/map', params);
/** 公众号接口 */
export const gzhService = (params) => post('/us/comment', params);
/** 公众号接口 */
export const h5Service = (params) => get('/share/h5', params);
/** 公众号接口 */
export const userService = (params) => get('/share/user', params);



/** 测试 */
export const testService1 = (params) => get('', params)
export const testService2 = (params) => post('', params)
export const testService3 = (params) => put('', params)
export const testService4 = (params) => del('', params)


