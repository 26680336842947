import { render, staticRenderFns } from "./fixedBtn.vue?vue&type=template&id=4570f9b7&scoped=true&"
import script from "./fixedBtn.vue?vue&type=script&lang=js&"
export * from "./fixedBtn.vue?vue&type=script&lang=js&"
import style0 from "./fixedBtn.vue?vue&type=style&index=0&id=4570f9b7&prod&lang=less&"
import style1 from "./fixedBtn.vue?vue&type=style&index=1&id=4570f9b7&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4570f9b7",
  null
  
)

export default component.exports