<!-- 头部导航栏 -->
<template>
	<div class="page-nav" :class="{'isTop': isTop, 'isHome': isHome, 'isPhone': isPhone}">
		<el-row class="nav f-c-c-c h100">
			<el-col class="hidden-xs-only lg-nav f-r-b-c h100">
					<el-col class="logo f-r-c-c h100" :xs="6" :sm="4" :md="4" :lg="3" @click.native="navTo('/home')">
						<img v-if="isTop&&isHome" src="@/assets/img/logo1.png" alt="拓世智能logo" />
						<img v-else src="@/assets/img/logo.png" alt="拓世智能logo" />
					</el-col>
					<el-col class="f-r-e-c h100"  :sm="20" :md="19" :lg="19">
						<div 
							class="nav-item h100"
							:class="{'nav-item-active': nowFatherRouter == item.page}"
							v-for="item in nav" :key="item.id">
							<el-tooltip placement="bottom" effect="light" v-if="item?.children?.length">
								<div class="f-c-s-s" slot="content" >
									<div 
										class="nav-tip" 
										:class="{'active': nowRouterQuery.sign == childrenItem.sign}"
										v-for="childrenItem in item.children" :key="childrenItem.id"
										@click.stop="navTo(childrenItem)">
										{{ childrenItem.class_name }}
									</div>
								</div>
								<div class="h100 f-r-c-c" @click="navTo(item)">{{ item.class_name }}</div>
							</el-tooltip>
							<template v-else>
								<div class="h100 f-r-c-c" @click="navTo(item)">{{ item.class_name }}</div>
							</template>
						</div>
						<div class="f-r-c-c h100">
							<el-tooltip placement="bottom" effect="light">
								<div slot="content" class="zx fixed_code f-c-c-c">
									<img :src="navTopConfig?.inquire_code.value" alt="">
									<p>{{navTopConfig?.inquire_code.remark}}</p>
								</div>
								<div class="f-r-c-c h100">
									<div class="icon">
										<i class="el-icon-s-custom"></i>
									</div>
								</div>
							</el-tooltip>
						</div>
					</el-col>
			</el-col>
			<el-col class="hidden-sm-and-up xs-nav f-r-b-c">
				<div class="logo" @click="navTo('/home')">
					<img src="@/assets/img/logo.png" alt="">
				</div>
				<div class="menu f-r-c-c">
					<i class="iconfont icon-nav"  @click.stop="isPhoneMenu = !isPhoneMenu"></i>
					<div class="list" :class="{'list-active': isPhoneMenu}">
						<ul>
							<li v-for="item in nav" :key="item.id">
								<p class="title" @click.stop="navTo(item)">{{ item.class_name }}</p>
								<p 
									class="text" 
									@click.stop="navTo(childrenItem)"
									v-for="childrenItem in item.children" :key="childrenItem.id">
									{{ childrenItem.class_name }}
								</p>
							</li>
						</ul>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {mapState} from "vuex"
	export default {
		name: "NavComponent",
		props:["nav", "navTopConfig"],
		data() {
			return {
				isHome: true,		// 是否为首页
				isTop: true,		// 是否为顶部
				isPhone: false,		// 是否为手机端
				isPhoneMenu: false,	// 
			};
		},
		computed: {
			...mapState(['nowRouter', 'nowRouterQuery', "nowFatherRouter"]),
		},
		watch: {
			// 辨别当前路由是否为 首页
			nowRouter: {
				handler(v){
					this.isHome = v == '/home' || v == "/";
					// let meta_keywords = "";
					// let meta_description = "";
					// let meta_title = "";
					// let rt = this.nowFatherRouter;
					// let sign = this.nowRouterQuery.sign || this.nowRouterQuery.id;
					// // console.log(sign, rt, v, this.nav)
					// if(sign && rt && this.nav && this.nav.length){
					// 	this.nav.some(d=>{
					// 		if(d.page == "/home" || d.page == "/"){
					// 			meta_keywords = d.meta_keywords || meta_keywords;
					// 			meta_description = d.meta_description || meta_description;
					// 			meta_title = d.meta_title || meta_title;
					// 			// console.log(888, meta_keywords, meta_description, meta_title)
					// 		}
					// 		if(d.page == rt || d.page == v){
					// 			meta_keywords = d.meta_keywords || meta_keywords;
					// 			meta_description = d.meta_description || meta_description;
					// 			meta_title = d.meta_title || meta_title;
					// 			// console.log(8818, meta_keywords, meta_description, meta_title)
					// 		}
					// 		if(d.sign == sign){
					// 			meta_keywords = d.meta_keywords || meta_keywords;
					// 			meta_description = d.meta_description || meta_description;
					// 			meta_title = d.meta_title || meta_title;
					// 			// console.log(8828, meta_keywords, meta_description, meta_title)
					// 		}
					// 		if(d?.children?.length){
					// 			return  d.children.some(dd=>{
					// 				if(dd.page == rt || dd.page == v){
					// 					meta_keywords = dd.meta_keywords || meta_keywords;
					// 					meta_description = dd.meta_description || meta_description;
					// 					meta_title = dd.meta_title || meta_title;
					// 					// console.log(8838, meta_keywords, meta_description, meta_title)
					// 				}
					// 				if(dd.sign == sign){
					// 					// console.log(999, dd)
					// 					meta_keywords = dd.meta_keywords || meta_keywords;
					// 					meta_description = dd.meta_description || meta_description;
					// 					meta_title = dd.meta_title || meta_title;
					// 					// console.log(8848, meta_keywords, meta_description, meta_title)
					// 				}
					// 				return dd.sign == sign;
					// 			})
					// 		}
					// 		return d.sign == sign;
					// 	})
					// }
					// // console.log(8858, meta_keywords, meta_description, meta_title)
					// if(meta_keywords || meta_keywords || meta_title){
					// 	this.setKeywords(meta_keywords, meta_description, meta_title);
					// }
				},
				immediate: true
			},
			nowRouterQuery: {
				handler(v){
					let meta_keywords = "";
					let meta_description = "";
					let meta_title = "";
					let sign = v.sign || v.id;
					let rt = this.nowFatherRouter;
					let page = this.nowRouter;
					// console.log(111, sign, rt, v, this, this.nav)
					let loop  = ()=>{
						if(sign && rt && this.nav && this?.nav?.length){
							this.nav.some(d=>{
								if(d.page == "/home" || d.page == "/"){
									meta_keywords = d.meta_keywords || meta_keywords;
									meta_description = d.meta_description || meta_description;
									meta_title = d.meta_title || meta_title;
									// console.log(888, meta_keywords, meta_description, meta_title)
								}
								if(d.page == rt || d.page == page){
									meta_keywords = d.meta_keywords || meta_keywords;
									meta_description = d.meta_description || meta_description;
									meta_title = d.meta_title || meta_title;
									// console.log(8818, meta_keywords, meta_description, meta_title)
								}
								if(d.sign == sign){
									meta_keywords = d.meta_keywords || meta_keywords;
									meta_description = d.meta_description || meta_description;
									meta_title = d.meta_title || meta_title;
									// console.log(8828, meta_keywords, meta_description, meta_title)
								}
								if(d?.children?.length){
									return  d.children.some(dd=>{
										if(dd.page == rt || dd.page == page){
											meta_keywords = dd.meta_keywords || meta_keywords;
											meta_description = dd.meta_description || meta_description;
											meta_title = dd.meta_title || meta_title;
											// console.log(8838, meta_keywords, meta_description, meta_title)
										}
										if(dd.sign == sign){
											// console.log(999, dd)
											meta_keywords = dd.meta_keywords || meta_keywords;
											meta_description = dd.meta_description || meta_description;
											meta_title = dd.meta_title || meta_title;
											// console.log(8848, meta_keywords, meta_description, meta_title)
										}
										return dd.sign == sign;
									})
								}
								return d.sign == sign;
							})
							// console.log(8858, meta_keywords, meta_description, meta_title)
							if(meta_keywords || meta_keywords || meta_title){
								this.setKeywords(meta_keywords, meta_description, meta_title);
							}
						}
						else{
							setTimeout(()=>{ loop(); }, 20)
						}
					}
					loop();
				},
				immediate: true
			}

		},
		mounted() {
			//获取操作元素最顶端到页面顶端的垂直距离
			let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
			this.isTop = scrollTop <= 0;
			// 添加滚动监听事件
			// 在窗口滚动时调用监听窗口滚动方法
			window.addEventListener('scroll', ()=>{
				//获取操作元素最顶端到页面顶端的垂直距离
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				this.isTop = scrollTop <= 0;
			});
			this.isPhone = document.body.clientWidth < 768;
		
		},
		methods: {
			navTo(item) {
				this.isPhoneMenu = false;
				let query = item.sign ? {sign: item.sign} : {};
				this.$router.push({path: item.page, query})
			},
		},
	};
</script>

<style lang='less'>
	.el-tooltip__popper.is-light{
		border: 0px !important;
		z-index: 9999 !important;
		padding: 10 !important;
		box-shadow: 0 0 10px rgb(0 0 0 / 15%);
		.nav-tip{
			cursor: pointer;
			padding: 10px 20px;
			
			&.active{
				border-radius: 4px;
				color: #3778F8;
				background: #E6F4FB;
			}
			&:hover{
				border-radius: 4px;
				color: #3778F8;
				background: #E6F4FB;
			}
		}
		.nav-serve-tip{
			div{
				padding: 20px;
				border-bottom: 1px solid #EFEFEF;
				p{
					white-space: nowrap;
					text-align: left;
					padding-top: 10px;
					&:nth-of-type(1){
						font-size: 18px;
						margin: 0 0 6px 0;
						padding: 0;
						font-weight: 700;
					}
					&:hover{
						cursor: default;
						// color: #3778F8;
					}
				}
				
				&:nth-last-of-type(1){
					padding-bottom: 20px;
					border-bottom: 0px;
				}
			}
		}
		.zx{
			background: rgba(231, 246, 255, 1);
		}
	}
</style>

<style scoped lang="less">
.page-nav{
	position: relative;
	height: 72px;
	width: 100%;
	box-shadow: 4px 4px 20px 2px rgba(50, 83, 238, 0.05);
	&.isHome{
		position: absolute;
	}
	&.isTop{
		.nav{
			background-color: #ffffff00;
		}
	}
	&.isPhone{
		position: relative;
	}
	&.isHome.isTop{
		.lg-nav{
			color: #FFF;
			.icon{
				color: #FFF;
				background-color: #ffffff00;
			}
		}
	}
	
}
.nav{
	position: fixed;
	z-index: 9998;
	height: 72px;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 4px 4px 20px 2px rgba(50, 83, 238, 0.05);
	
	.lg-nav{
		height: 72px;
		width: 100%;
		max-width: 1200px;
		.logo {
			cursor: pointer;
			position: relative;
			img {
				position: absolute;
				top: 50%;
				left: 0px;
				transform: translateY(-50%);
				padding: 0px 15px;
				height: 36px;
			}
		}
		.nav-item {
			cursor: pointer;
			padding: 0px 20px;
			font-size: 14px;
			&:hover{
				color: #3778F8 !important;
			}
			&.nav-item-active{
				color: #3778F8 !important;
			}
		}
		.icon{
			cursor: pointer;
			width: 96px;
			text-align: center;
			height: 36px;
			line-height: 36px;
			border-radius: 6px;
			background: #F2FEFF;
			color: #008FD7;
			font-size: 24px;
		}
	}
	.xs-nav{
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 9998;
		height: 72px;
		width: 100%;
		background: #ffffff;
		box-shadow: 4px 4px 20px 2px rgba(50, 83, 238, 0.05);
		.logo{
			padding-left: 20px;
			height: 28px;
			img{
				height: 100%;
			}
		}
		.menu{
			position: relative;
			height: 72px;
			.icon-nav{
				padding: 0px 30px;
				font-size: 24px;
				color: #353535;
			}
			.list{
				display: none;
				position: absolute;
				top: 99%;
				right: 0px;
				z-index: -1;
				// height: 0px;
				padding: 20px 20px;
				width: 100vw;
				opacity: 0;
				background: #ffffff;
				border-radius: 0px  0px 10px 10px;
				transition: all 0.3s;
				ul{
					overflow-y: auto;
					max-height: 300px;
					li{
						.title{
							font-size: 14px;
							font-weight: 700;
							line-height: 36px;
						}
						.text{
							padding-left: 20px;
							font-size: 14px;
							font-weight: 500;
							line-height: 36px;
						}
					}
				}
			}
			&:hover{
				.list-active{
					display: block;
					opacity: 1;
					border-top: 1px solid #d5d5d5;
					box-shadow: 0 10px 10px rgb(0 0 0 / 15%);
				}
			}
		}
	}
	
}
</style>

