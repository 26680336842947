import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/page/home/index.vue'
import store from "@/store"
// import HomeView from '@/page/gzh/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

//push方法封装
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};
//replace方法封装
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};

export const routes = [
	{
		path: '/',
		// redirect: "/gzh",
		component: HomeView
	},
	{
		path: '/gzh',
		name: 'gzh',
		component: () => import('@/page/gzh/index.vue')
	},
	{
		path: '/home',
		name: 'home',
		meta: {title: "首页"},
		component: HomeView
	},
	{
		path: "/AI",
		name: "AI",
		meta: {title: "测试"},
		redirect: "/AI/digitalperson",
		component: () => import('@/page/AI/index.vue'),
		children: [
			{
				path: 'digitalperson',
				name: 'digitalperson',
				meta: {title: "demo1"},
				component: () => import('@/page/AI/digitalperson.vue'),
			},
			{
				path: 'guide',
				name: 'guide',
				meta: {title: "demo1"},
				component: () => import('@/page/AI/guide.vue'),
			},
			{
				path: 'download',
				name: 'download',
				meta: {title: "软件下载"},
				component: () => import('@/page/AI/download.vue'),
				children: []
			},{
				path: 'downLoadTool',
				name: 'downLoadTool',
				meta: {title: "软件下载"},
				component: () => import('@/page/AI/downLoadTool.vue'),
				children: []
			},
		]
	},
	{
		path: '/product',
		name: 'product',
		meta: {title: "产品技术"},
		redirect: "/product/technology",
		component: () => import('@/page/product/index.vue'),
		children: [
			{
				path: 'technology',
				name: 'technology',
				meta: {title: "核心技术"},
				component: () => import('@/page/product/technology.vue'),
			},
			{
				path: 'list',
				name: 'list',
				meta: {title: "产品中心"},
				component: () => import('@/page/product/list.vue'),
			},
			{
				path: 'detail',
				name: 'prodictDetail',
				component: () => import('@/page/news/detail.vue')
			},
		]
	},
	{
		path: '/project',
		name: 'project',
		meta: {title: "解决方案"},
		redirect: "/project/list",
		component: () => import('@/page/project/index.vue'),
		children: [
			{
				path: 'detail',
				name: 'projectDetail',
				component: () => import('@/page/news/detail.vue')
			},
			{
				path: 'list',
				name: 'projectList',
				component: () => import('@/page/project/list.vue')
			},
		]
	},
	{
		path: '/news',
		name: 'news',
		meta: {title: "新闻中心"},
		redirect: "/news/list",
		component: () => import('@/page/news/index.vue'),
		children: [
			{
				path: 'detail',
				name: 'newsDetail',
				component: () => import('@/page/news/detail.vue')
			},
			{
				path: 'list',
				name: 'newsList',
				component: () => import('@/page/news/list.vue')
			},
		]
	},
	{
		path: '/about',
		name: 'about',
		redirect: "/project/index",
		component: () => import('@/page/about/index.vue'),
		children: [
			{
				path: 'index',
				name: 'aboutList',
				component: () => import('@/page/about/about.vue')
			},
			{
				path: 'detail',
				name: 'aboutDetail',
				component: () => import('@/page/news/detail.vue')
			}
		]
	},
	// {
	// 	path: '/team',
	// 	name: 'team',
	// 	meta: {title: "关于我们"},
	// 	redirect: "/team/list",
	// 	component: () => import('@/page/team/index.vue'),
	// 	children: [
	// 		{
	// 			path: 'detail',
	// 			name: 'newsDetail',
	// 			component: () => import('@/page/news/detail.vue')
	// 		},
	// 		{
	// 			path: 'list',
	// 			name: 'newsList',
	// 			component: () => import('@/page/team/list.vue')
	// 		},
	// 	]
	// },
	{
		path: '*', //匹配未定义的路由
		redirect: '/home' //重定向
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


router.beforeEach( async (to, from, next) => {
	// console.log(to)
	store.state.nowRouterQuery = to.query || {};		// 当前路由参数
	store.state.nowRouter = to.path;					// 当前路由(全)
	store.state.nowFatherRouter = to.matched[0].path; 		// 当前路由(主路由)
	// console.log(to)
	// console.log( store.state.nowFatherRouter)

	next()
})


export default router;
