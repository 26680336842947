<template>
  <div class="home">
    <!-- 轮播图@touchmove="a" -->
    <div
      class="loop-img"
      @touchstart.prevent="clientX[0] = $event.targetTouches[0].clientX"
      @touchmove.prevent="clientX[1] = $event.targetTouches[0].clientX"
      @touchend.prevent="switchImg"
    >
      <el-carousel ref="carousel" height="auto" @change="change">
        <el-carousel-item v-for="item in banner" :key="item">
          <!-- <img :src="item" alt="轮播图背景1"> -->
          <el-image
            ref="image"
            style="width: 100%"
            :src="item"
            fit="fit"
            class="banner-img"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 介绍 -->
    <div v-if="homeAbout">
      <el-row class="describe f-c-c-c">
        <el-col class="hidden-xs-only lg-describe">
            <el-row type="flex" justify="center">
              <el-col :span="12">
                <img :src="homeAbout.sub_icon" alt="">
              
              </el-col>
              <el-col class="f-c-s-s" :span="11" style="margin-left: 54px;">
                <div class="title">{{homeAbout.class_name}}</div>
                <div class="subTitle">{{homeAbout.class_subhead_name}}</div>
                <div class="content">{{homeAbout.class_intro}}</div>
                <div class="btn">
                  <el-button type="primary" @click="navTo(homeAbout)">了解更多</el-button>
                </div>
              </el-col>
            </el-row>
        </el-col>
        <el-col class="hidden-sm-and-up xs-describe">
          <div class="title">{{homeAbout.class_name}}</div>
          <div class="subTitle">{{homeAbout.class_subhead_name}}</div>
          <div class="content">{{homeAbout.class_intro}}</div>
          <div class="btn">
            <el-button type="primary" @click="navTo(homeAbout)">了解更多</el-button>
          </div>
          <div class="img">
            <img :src="homeAbout.sub_icon" alt="">
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 核心技术 -->
    <div v-if="technology">
      <el-row class="core f-c-c-c">
        <el-col class="hidden-xs-only lg-core f-c-c-c">
          <div class="title">{{technology.class_name}}</div>
          <div class="subTitle">{{technology.class_subhead_name}}</div>
          <ul class="content f-r-c-s">
            <li v-for="t in technology.list" :key="t.id">
              <div class="box">
                 <div class="info f-r-a-c">
                    <span class="title">{{t.title}}</span>
                    <div class="img">
                      <img :src="t.icon" alt="">
                    </div>
                  </div>
                  <div class="info-hover no-scroll">
                    <span class="title">{{t.title}}</span>
                    <div class="text">{{t.desc}}</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="btn">
            <el-button type="primary"  @click="navTo(technology)">了解更多</el-button>
          </div>
        </el-col>
        <el-col class="hidden-sm-and-up xs-core f-c-c-c">
          <div class="title">{{technology.class_name}}</div>
          <div class="subTitle">{{technology.class_subhead_name}}</div>
          <ul class="content f-c-s-c">
            <li class="f-r-a-c" v-for="tt in technology.list" :key="`t_${tt.id}`">
              <span class="title">{{tt.title}}</span>
              <div class="img">
                <img :src="tt.icon" alt="">
              </div>
            </li>
          </ul>
          <div class="btn">
            <el-button type="primary" @click="navTo(technology)">了解更多</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- AI数字人 -->
    <div v-if="ai" class="ai_bg">
      <el-row class="ai f-c-c-c">
        <el-col class="hidden-xs-only lg-ai f-c-c-c">
          <div class="title">{{ai.class_name}}</div>
          <div class="text">{{ai.class_intro}}</div>
          <div class="btn">
            <el-button type="primary" @click="navTo(ai)">了解更多</el-button>
          </div>
          <div class="img">
            <img :src="ai.sub_icon" alt="">
          </div>
        </el-col>
        <el-col class="hidden-sm-and-up xs-ai f-c-c-c">
          <div class="title">{{ai.class_name}}</div>
          <div class="text">{{ai.class_intro}}</div>
          <div class="btn">
            <el-button type="primary" @click="navTo(ai)">了解更多</el-button>
          </div>
          <div class="img">
            <img :src="ai.sub_icon" alt="">
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 产品方案 -->
    <div v-if="product">
      <el-row class="product f-c-c-c">
        <el-col class="hidden-xs-only lg-product f-c-c-c">
          <div class="title">{{product.class_name}}</div>
          <div class="text">{{product.class_subhead_name}}</div>
          <ul class="list f-r-s-s">
            <li v-for="p in product.list" :key="p.id" @click="navTo_Id(p)">
              <div class="img">
                <img :src="p.icon||p.sub_icon" alt="">
              </div>
              <div class="info">
                <div class="info-box f-c-e-s">
                  <div class="content-title">{{p.title}}</div>
                  <div class="content-text ">Category / {{p.class_name}}</div>
                  <div class="content-icon"><img src="@/assets/img/arrow2.png" alt=""></div>
                </div>
              </div>
            </li>
          </ul>
          <div class="btn1 f-r-c-c">
            <el-button type="primary" @click="navTo(product)">查看更多</el-button>
          </div>
        </el-col>
        <el-col class="hidden-sm-and-up xs-product">
          <div class="title">{{product.class_name}}</div>
          <div class="text">{{product.class_subhead_name}}</div>
           <ul class="list f-r-s-s">
            <li v-for="p in product.list" :key="p.id">
              <img :src="p.icon||p.sub_icon" alt="">
              <div class="info">{{p.title}}</div>
            </li>
          </ul>
          <div class="btn f-r-c-c">
            <el-button type="primary" @click="navTo(product)">查看更多</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 合作方 -->
    <div v-if="partner">
      <el-row class="partner">
        <el-col class="hidden-xs-only lg-partner f-c-c-c">
          <div class="title">{{partner.class_name}}</div>
          <div class="subTitle">{{partner.class_subhead_name}}</div>
          <div class="loop">
            <!-- <div class="loop-box f-r-s-s" v-for="n in 3" :key="n" :class="{'f-r-e-s': n== 2}"> -->
            <div class="loop-box f-r-s-s" v-for="n in 3" :key="n">
              <template v-if="n == 1">
                <div class="loop-img"  v-for="p in partner.list[n-1]" :key="p.id"><img :src="p.icon" alt=""></div>
              </template>
              <template  v-if="n == 2">
                <div class="loop-img"  v-for="p in partner.list[n-1]" :key="p.id"><img :src="p.icon" alt=""></div>
              </template>
              <template  v-if="n == 3">
                <div class="loop-img"  v-for="p in partner.list[n-1]" :key="p.id"><img :src="p.icon" alt=""></div>
              </template>
              <template v-if="n == 1">
                <div class="loop-img"  v-for="p in partner.list[n-1]" :key="`p_${p.id}`"><img :src="p.icon" alt=""></div>
              </template>
              <template  v-if="n == 2">
                <div class="loop-img"  v-for="p in partner.list[n-1]" :key="`p_${p.id}`"><img :src="p.icon" alt=""></div>
              </template>
              <template  v-if="n == 3">
                <div class="loop-img"  v-for="p in partner.list[n-1]" :key="`p_${p.id}`"><img :src="p.icon" alt=""></div>
              </template>
            </div>
          </div>
        </el-col>
        <el-col class="hidden-sm-and-up xs-partner">
          <div class="title">拓世，服务客户，上千家品牌企业首选</div>
          <div class="subTitle">用行动捍卫客户信任，超过1000家品牌企业的选择</div>
          <ul >
            <li class=" f-r-c-s">
              <div class="loop-img"  v-for="p in partner.list[1]" :key="`p_${p.id}`"><img :src="p.icon" alt=""></div>
              <div class="loop-img"  v-for="p in partner.list[2]" :key="`p_${p.id}`"><img :src="p.icon" alt=""></div>
              <div class="loop-img"  v-for="p in partner.list[3]" :key="`p_${p.id}`"><img :src="p.icon" alt=""></div>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { homeService } from "@/utils/api/index.js";
export default {
  name: "HomePage",
  data() {
    return {
      topUrl: "about-us.jpg",
      title: "关于我们",
      text: "我们已经上路，我们将为此不懈努力，期待得到您的支持",
      clientX: [], // 手机端滑动时开始与结束的距离
      n: "", // 当前轮播
      banner: [],           // 轮播图
      homeAbout: "",        // 拓世智能
      technology: "",       // 核心技术
      ai: "",               // AI数字人
      product: "",          // 产品方案
      partner: "",          // 合作伙伴

      partnerBg: "client-bg.jpg", // 服务客户背景图
    };
  },
  created() {
    this.toTop();
    homeService().then((res) => {
      // console.log('首页', res.data)
      if (res.code == 0) {
        let d = res.data;
        this.banner = d.back_pic;                           // 轮播图
        this.homeAbout = d.children.home_about_us;          // 拓世智能
        this.technology = d.children.home_core_technology;  // 核心技术
        this.ai = d.children.home_ai;                       // AI数字人
        this.product = d.children.home_product_project;     // 产品方案
        this.partner = d.children.partner;                  // 合作伙伴


        this.$store.commit("setIsGrey", d.is_grey);     // 重大事件置灰
        if (d.is_grey == "1") {
          let html = document.getElementsByTagName("html")[0];
          html.className = "Mourning";
        }
        this.setKeywords(d.meta_keywords, d.meta_description, d.meta_title);
      }
    });
  },
  computed: {
    partnerBgImg: function () {
      let partnerBg = this.partnerBg;
      return require(`@/assets/img/${partnerBg}`);
    },
  },
  methods: {
    // 轮播图切换
    switchImg() {
      let x = this.clientX;
      let len = x[0] - x[1];
      if (len > 100) {
        this.$refs.carousel.next();
      } else if (len < -100) {
        this.$refs.carousel.prev();
      }
    },
    // 选择图片
    change(n) {
      this.n = n;
    },
    // 路由跳转
    navTo(item){
      let query = item.sign ? {sign: item.sign} : {};
      this.$router.push({path: item.page, query});
    },
    navTo_Id(item){
      // console.log(999, item)
      this.$router.push({path: "/product/detail", query: {id: item.id}})
    },
  },
};
</script>


<style scoped lang="less">

@keyframes aaa {
    from {
        transform: translateX(0%);
    }
  
    to {
      transform: translateX( calc(-100% + 100vw));
    }
}
@keyframes bbb {
    from {
        transform: translateX(calc(-100% + 100vw));
    }
  
    to {
      transform: translateX(0%);
    }
}
.describe{
  width: 100%;
  .lg-describe{
    max-width: 1200px;
    padding: 100px 20px 0px;
    img{
      width: 100%;
      height: calc(100% - 100px);
      border-radius: 5%;
    }
    .title{
      font-size: 30px;
      line-height: 42px;
      font-weight: 600;
      color: #353535;
    }
    .subTitle{
      padding-top: 20px;
      font-size: 20px;
      line-height: 33.6px;
      font-weight: 400;
      color: #353535;
    }
    .content{
      padding-top: 20px;
      font-size: 14px;
      line-height: 28px;
      color: #888;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: normal !important;
      //显示6行
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }
    .btn{
      margin-top: auto;
      padding-top: 20px;
      padding-bottom: 100px;
      button{
        height: 40px;
        width: 120px;
      }
    }

  }
  .xs-describe{
    padding: 10px 20px;
    width: 100%;
    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 50px;
    }
    .subTitle{
      font-size: 14px;
      font-weight: 600;
      line-height: 30px;
    }
    .content{
      padding-top: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #888888;
    }
    .btn{
      padding: 20px 0px;
      button{
        width: 120px;
      }
    }
    .img{
      width: 100%;
      img{
        width: 100%;
        border-radius: 5%;
      }
    }
  }
}
.core{
  width: 100%;
  // font-family: PingFang SC;
  background: #222B41;
  .lg-core{
    width: 100%;
    max-width: 1200px;
    padding: 80px 0px;
    .title{
      color: #FFF;
      font-size: 32px;
      font-weight: 600;
    }
    .subTitle{
      color: #FFF;
      padding-top: 28px;
      font-size: 24px;
      font-weight: 400;
    }
    .content{
      flex-wrap: wrap;
      li{
        height: 210px;
        width: 360px;
        margin: 0px 15px;
        margin-top: 38px;
        .box{
          position: relative;
          cursor: pointer;
          height: 100%;
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
          &:hover .info{
            opacity: 0;
          }
          &:hover .info-hover{
            height: 100%;
            width: 100%;
            opacity: 1;
          }
          .info{
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(35, 55, 82, 0.6);
            transition: all 0.3s;
            overflow: hidden;
            .title{
              font-size: 28px;
              font-weight: 500;
              line-height: 42px;
            }
            .img{
              height: 94px;
              width: 94px;
              border-radius: 20px;
              overflow: hidden;
              img{
                height: 100%;
                width: 100%;
              }
            }
          }
          .info-hover{
            position: absolute;
            padding: 20px 34px;
            height: 0%;
            width: 0%;
            opacity: 0;
            border-radius: 5px;
            background: rgba(55, 116, 255, 0.6);
            overflow-y: auto;
            transition: all 0.3s;
            .title{
              font-size: 30px;
              font-weight: 500;
              line-height: 42px;
            }
            .text{
              margin-top: 10px;
              padding-left: 10px;
              // font-family: "Hiragino Sans GB";
              white-space: pre-wrap;
              color: #FFF;
              font-size: 14px;
              // font-weight: 600;
              line-height: 30px;
            }
          }
        }
        
        
      }
    }
    .btn{
      padding-top: 34px;
      button{
        width: 120px;
      }
    }
  }
  .xs-core{
    width: 100%;
    padding: 20px;
    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 50px;
      color: #FFF;
    }
    .subTitle{
      font-size: 14px;
      font-weight: 600;
      line-height: 36px;
      color: #FFF;
    }
    .content{
      width: 100%;
      li{
        padding: 20px;
        width: 100%;
        height: 48vw;
        border-radius: 5%;
        margin: 10px 0px;
        background: rgba(35, 55, 82, 0.6);
        span{
          font-size: 16px;
        }
        img{
          width: 68px;
        }
      }
      
    }
    .btn{
      padding-top: 20px;
      button{
        width: 120px;
      }
    }
  }
}
.ai_bg{
  background-image: url("@/assets/img/home/ai_bg.png");
  background-size:  100vw 180vw;
  background-position-x: 0%;
  background-position-y: 50%;
  background-repeat: no-repeat;
}
.ai{
  background: rgba(255, 255, 255, 0.8);
  // padding: 100px 0px 168px; 
  .lg-ai{
    width: 100%;
    max-width: 1200px;
    padding: 100px 20px 168px;
    // height: 846px;
    .title{
      font-size: 36px;
      font-weight: 600;
      line-height: 50px;
    }
    .text{
      padding-top: 20px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 36px;
      color: #AAAAAA;
    }
    .btn{
      padding-top: 34px;
      button{
        width: 120px;
      }
    }
    .img{
      padding: 120px 20px 0px;
      img{
        width: 100%;
      }
    }
  }
  .xs-ai{
    text-align: center;
    width: 100%;
    padding: 20px;
    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 50px;
    }
    .text{
      padding-top: 22px;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #AAA;
    }
    .btn{
      padding: 20px;
      button{
        width: 120px;
      }
    }
    .img{
      padding-bottom: 20px;
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
}
.product{
  background: rgb(243, 247, 253);
  .lg-product{
    padding: 100px 0px;
    width: 100%;
    max-width: 1200px;
    .title{
      font-size: 36px;
      font-weight: 600;
      line-height: 32px;
    }
    .text{
      padding-top: 30px;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      color: #AAA;
    }
    .list{
      padding-top: 60px;
      width: 100%;
      flex-wrap: wrap;
      li{
        cursor: pointer;
        position: relative;
        width: 33.33%;
        padding: 0px 10px 30px;
        height: 23vw;
        max-height: 275px;
        border-radius: 5px;
        &:nth-of-type(1){
          width: 66.66%;
          height: 28.33vw;
          max-height: 340px;
        }
        &:nth-of-type(2){
          height: 28.33vw;
          max-height: 340px;
        }
        .img{
          width: 100%;
          height: 100%;
          border-radius: 5px;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .info{
          position: absolute;
          z-index: 1;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          padding: 0px 10px 30px;
          // opacity: 0;
          color: #FFF;
          overflow: hidden;
          .info-box{
            height: 100%;
            width: 100%;
            padding: 20px 20px;
            background: #306BFC66;
            background: linear-gradient(359.4deg, rgba(50, 83, 238, 0.5) 5.96%, rgba(255, 255, 255, 0) 99.16%);
            .content-title{
                width: 100%;
                height: 16px;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                transition: transform 0.3s;
                transform: translateY(48px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .content-text{
                padding-top: 6px;
                height: 24px;
                font-size: 12px;
                line-height: 14px;
                opacity: 0;
                transition: transform 0.3s;
                transition-delay: 150ms;
                transform: translateY(48px);
            }
            .content-icon{
                height: 12px;
                font-size: 14px;
                opacity: 0;
                transition: transform 0.3s;
                transition-delay: 300ms;
                transform: translateY(48px);
                img{
                    width: 28px;
                    height: 12px;
                }
            }
          }
        }
        &:hover{
          .info{
            .info-box{
              background: #306BFCB2;
              .content-title{
                  transform: translateY(0px);
              }
              .content-text{
                  opacity: 1;
                  transform: translateY(0px);
              }
              .content-icon{
                  opacity: 1;
                  transform: translateY(0px);
              }
            }
          }
        }
      }
    }
    .btn{
      padding-top: 34px;
      button{
        width: 120px;
      }
    }
  }
  .xs-product{
    text-align: center;
    width: 100%;
    padding: 20px 0px;
    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 50px;
    }
    .text{
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #AAA;
    }
    .list{
      flex-wrap: wrap;
      width: 100%;
      li{
        position: relative;
        height: 38vw;
        width: 50vw;
        max-width: 50vw;
        padding: 10px 10px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
        .info{
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          padding: 10px 20px;
          color: #FFF;
          font-size: 12px;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .btn{
      padding-top: 20px;
      button{
        width: 120px;
      }
    }
  }

}
.partner{
  width: 100%;
  .lg-partner{
    padding: 100px 0px;
    width: 100%;
    .title{
      font-size: 36px;
      font-weight: 600;
      line-height: 32px;
      color: #353535;
    }
    .subTitle{
      margin-top: 30px;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      color: #888888;

    }
    .loop{
      margin-top: 40px;
      width: 100%;
      position: relative;
      height: 248px;
      .loop-box{
          position: absolute;
        .loop-img{
          cursor: pointer;
          width: 184px;
          min-width: 184px;
          height: 98px;
          img{
            width: 184px;
            height: 98px;
          }
        }
        &:nth-of-type(1){
          animation: aaa 50s linear infinite alternate;
        }
        &:nth-of-type(2){
          top: 112px;
          animation: aaa 50s linear infinite alternate-reverse;
          // animation: aaa 10s linear infinite alternate-reverse;
        }
        &:nth-of-type(3){
          flex-direction: row-reverse;
          top: 224px;
          animation: aaa 50s linear infinite alternate;
          // animation: aaa 10s linear infinite alternate;
        }
        &:hover{
          animation-play-state: paused;
        }

      }
    }
  }
  .xs-partner{
    text-align: center;
    width: 100%;
    padding: 20px 0px;
    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 50px;
    }
    .subTitle{
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #AAA;
    }
    ul{
      width: 100%;
      li{
        flex-wrap: wrap;
        img{
          cursor: pointer;
          width: 25vw;
        }
      }
    }

  }
}
</style>

<style scoped>
.loop-img img {
  position: relative;
  width: 100%;
  height: 100%;
}

.team .el-carousel__item:nth-of-type(1),
.loop-img .el-carousel__item:nth-of-type(1) {
  position: relative !important;
}
</style>

